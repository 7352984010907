<template>
  <div class="form-box">
    <div class="form-title">
      <h3 class="title">{{ title }}</h3>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        class="form-item"
        v-model="formData.name"
        name="name"
        label="您的姓名"
        label-width="1.2rem"
        label-align="right"
        colon
        placeholder="请输入您的姓名"
        :rules="[{ required: true, message: '请填写您的姓名' }]"
      />
      <van-field
        class="form-item"
        v-model="formData.phone"
        name="phone"
        label="手机号码"
        label-align="right"
        label-width="1.2rem"
        maxlength="11"
        colon
        type="tel"
        placeholder="请输入您的手机号"
        @input="changePhone"
        :rules="[
          { required: true, message: '请输入您的手机号' },
          {
            validator: validatorPhone,
            message: '手机号格式不正确',
            trigger: 'onBlur',
          },
        ]"
      />
      <van-field
        class="form-item"
        style="padding: .22rem .12rem"
        v-model="formData.code"
        center
        colon
        label="验证码"
        name="code"
        label-align="right"
        label-width="1rem"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
        v-if="showCode && needCode"
      >
        <template #button>
          <van-button
            class="sendBtn"
            native-type="button"
            :disabled="sendDisabled"
            size="small"
            @click="sendCodeFun"
            type="primary"
            >{{ sendText }}</van-button
          >
        </template>
      </van-field>
      <van-button native-type="submit" class="submit-btn">{{
        btnTitle
      }}</van-button>
    </van-form>
    <div class="agreement-item">
      <p>
        点击预约或领取表示您已同意<span @click="showAgreementFun"
          >《用户隐私协议》</span
        >
      </p>
    </div>
    <div class="loading-g" v-show="shoLoading">
      <div class="loading-wrap">
        <van-loading size="24px" color="#fff" vertical>加载中...</van-loading>
      </div>
    </div>
    <van-action-sheet v-model="showAgreement" title="用户隐私协议">
      <div class="agreementContent">
        <p>
          本站非常重视用户信息的保护，在使用本站的所有产品和服务前，请您务必仔细阅读并透彻理解本协议内容。一旦您选择使用，即表示您认可并接受本协议条款现有内容及其可能随时更新的内容。
        </p>
        <p>
          本协议仅适用于【如山选墓】网站及其主体
          “成都众兴未来科技有限公司”公司提供的系列产品或服务。（上述网站/主体合称“信息使用方”）
        </p>
        <p>
          本主体提供的产品和服务的运营商及其关联公司深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，本站承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <p>
          请在使用我们的产品（或服务）前，仔细阅读并了解本《用户隐私协议》。
        </p>
        <p>一、如何收集和使用您的个人信息</p>
        <p>
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息
        </p>
        <p>本站仅会出于本协议所述的以下目的，收集和使用您的个人信息：</p>
        <p>（一）为您提供的基本服务</p>
        <p>1、业务功能一：注册成为用户。</p>
        <p>
          为完成创建账号，您需提供以下信息：您的姓名、电子邮箱地址、创建的用户名和密码。在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：手机号、工作职位、公司、教育背景。但如果您不提供这些信息，将不会影响使用本服务的基本功能。
        </p>
        <p>2、业务功能二：发送促销营销信息。</p>
        <p>
          为向您推荐适合的服务，您需提供以下信息：您的姓名、电话、电子邮箱地址、用户名。但法律法规不允许本站进行推荐的，本站对相关产品向您发送营销信息。您可以依据本协议接收或拒绝接收相关营销信息。
        </p>
        <p>3、业务功三：支付结算。</p>
        <p>
          为完成线上支付服务，您需提供以下信息：您的姓名、电话、身份证号码、电子支付账号。
        </p>
        <p>4、业务功能四：交付产品或服务。</p>
        <p>
          为向您配送所购买的商品，您需提供以下信息：您的姓名、电话、联系地址。
        </p>
        <p>（二）推荐产品或服务</p>
        <p>
          在法律法规和行政主管部门允许的前提下，本站将根据您的主动申请向您推荐合适产品或服务。为进行推荐，您需提供以下信息的部分或全部：您的姓名、性别、手机号、验证码、身份证号、年龄、出生日期、以及和申请产品或服务相关的内容信息。
        </p>
        <p>
          在推荐过程中，如果您提供以下额外信息的部分或全部，将有助于信息使用方给您提供更好的服务和体验：出行方式、子女情况、收入情况、兴趣爱好、身体健康状况、房产情况、机动车保有情况、信用卡申请情况、保单情况、工作职位、公司、教育背景等。但如果您不提供这些信息，将不会影响使用相关服务的基本功能。
        </p>
        <p>
          向第三方推荐您的信息时，我们会按照第三章“我们如何共享、转让、公开披露您的个人信息”的要求进行，并按照本隐私协议的规定保护您的合法权益。
        </p>
        <p>
          特别声明，根据相关法律法规或行政主管部门的要求，部分产品或服务不允许本站进行推荐。但如果您仍然对相关产品或服务有交易意愿时，本站将就相关产品向您提供广告或营销推广信息，但不对产品或服务的内容进行任何形式的推荐；您可以根据相关的广告或营销推广信息自行选择合适的产品或服务。
        </p>
        <p>（三）开展内部审计、数据分析和研究，改善我们的产品或服务</p>
        <p>
          为了规范本站的用户信息保护，为了向您和其他用户提供更好的服务，我们会使用您所提供的信息进行分析和研究。我们进行分析和研究时，会对您的信息进行去标识化处理。
        </p>
        <p>（四）其他</p>
        <p>当我们要将信息用于本协议未载明的其它用途时，会事先征求您的同意。</p>
        <p>
          当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
        </p>
        <p>
          您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。
        </p>
        <p>二、我们如何使用 Cookie 和同类技术</p>
        <p>（一）Cookie</p>
        <p>
          为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
          的小数据文件。Cookie
          通常包含标识符、站点名称以及一些号码和字符。借助于
          Cookie，网站能够存储您的偏好或购物篮内的商品等数据。
        </p>
        <p>
          我们不会将 Cookie
          用于本协议所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookie。有关详情，请参见
          AboutCookies.org。您可以清除计算机上保存的所有
          Cookie，大部分网络浏览器都设有阻止 Cookie
          的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。
        </p>
        <p>（二）网站信标和像素标签</p>
        <p>
          除 Cookie
          外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <p>（三）Do Not Track（请勿追踪）</p>
        <p>
          很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
          Track
          请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
          Do Not Track，那么我们的所有网站都会尊重您的选择。
        </p>
        <p>三、我们如何共享、转让、公开披露您的个人信息:</p>
        <p>（一）共享</p>
        <p>
          我们不会与信息使用方以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
        </p>
        <p>
          （1）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
        </p>
        <p>
          （2）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息；
        </p>
        <p>
          （3）与我们的附属公司、关联公司共享：为达成本隐私协议第一章项下的服务目的，您的个人信息可能会与成都众兴未来科技有限公司的附属公司、关联公司共享，我们只会共享必要的个人信息，且受本隐私协议中所声明目的的约束，附属公司、关联公司如要改变个人信息的处理目的，将再次征求您的授权同意；
        </p>
        <p>
          （4）与授权合作伙伴共享：仅为实现本协议中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。
        </p>
        <p>
          对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私协议以及其他相关的保密和安全措施来处理个人信息。
        </p>
        <p>（二）转让</p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>
          1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </p>
        <p>
          2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私协议的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>（三）公开披露</p>
        <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1、获得您明确同意后；</p>
        <p>
          2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
        <p>四、我们如何保护您的个人信息</p>
        <p>
          （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
        </p>
        <p>
          （二）我们会采取合理可行的措施，确保未收集无关的个人信息。我们只会在达成本协议所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p>
          （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p>
          （四）我们将定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。您可通过查看网站公告的方式获得。
        </p>
        <p>
          （五）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。
        </p>
        <p>
          （六）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p>
          同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>
        <p>五、您的权利</p>
        <p>
          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </p>
        <p>（一）访问您的个人信息</p>
        <p>
          您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
        </p>
        <p>
          账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问个人账户的管理页面行此类操作。
        </p>
        <p>
          如果您无法通过上述链接访问这些个人信息，您可以随时联系本站客服。我们将在30天内回复您的访问请求。
        </p>
        <p>
          对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。
        </p>
        <p>（二）更正您的个人信息</p>
        <p>
          当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
        </p>
        <p>（三）删除您的个人信息</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规；</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
        <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
        <p>5、如果我们不再为您提供产品或服务。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p>
          当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <p>（四）改变您授权同意的范围</p>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成（见本协议“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时联系本站客户，给予或收回您的授权同意。我们将在30天内回复您的变更请求。
        </p>
        <p>
          当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p>如果您不想接受我们给您发送的商业广告，您随时可通过以下方式取消：</p>
        <p>（1）使用短信或电子邮件中的退订；</p>
        <p>
          （2）若您无法使用（1）中的方式取消，您可以随时联系本站客服，要求退订我们的商业广告，我们将在30天内回复您的取消请求。
        </p>
        <p>（五）个人信息主体注销账户</p>
        <p>
          您随时可注销此前注册的账户，您可以访问个人账户管理页面注销您的账户。
        </p>
        <p>
          在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
        </p>
        <p>（六）个人信息主体获取个人信息副本</p>
        <p>
          您有权获取您的个人信息副本，您可以随时联系本站客服，我们将在30天内回复您的获取请求。
        </p>
        <p>（七）约束信息系统自动决策</p>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
        </p>
        <p>（八）第三方信息处理</p>
        <p>
          在您明确同意将信息交给第三方以提供产品或服务后，若您想要变更、删除第三方所保有的您的个人信息，您可以向第三方发送变更、删除您个人信息的通知，亦可要求本站为您向第三方转达变更、删除您个人信息的通知。
        </p>
        <p>
          但您知晓并认可，除本站的关联公司外，第三方是否变更、删除您的个人信息，不本站级相关主体的控制。您承诺，只要本站向第三方共享、转让或披露您个人信息之时不违反法律法规，您与第三方之间就您个人信息产生的纠纷，您不会要求本站承担相应责任，但本站将配合您的合理维权。
        </p>
        <p>（九）响应您的上述请求</p>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        </p>
        <p>
          我们将在三十天内做出答复。如您不满意，还可以通过本站产品页面上载明的联系方式进行申诉。
        </p>
        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
        <p>6、涉及商业秘密的。</p>
        <p>六、我们如何处理未成年人的个人信息</p>
        <p>
          我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。
        </p>
        <p>
          对于经父母同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
        </p>
        <p>
          尽管当地法律和习俗对未成年人的定义不同，但我们将不满
          18周岁的任何人均视为未成年人。
        </p>
        <p>
          如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
        </p>
        <p>七、本协议如何更新</p>
        <p>我们的隐私协议可能变更。</p>
        <p>
          未经您明确同意，我们不会削减您按照本隐私协议所应享有的权利。我们会在本页面上发布对本协议所做的任何变更。
        </p>
        <p>
          对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私协议的具体变更内容）。
        </p>
        <p>本协议所指的重大变更包括但不限于：</p>
        <p>
          1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p>2、个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>3、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>4、个人信息安全影响评估报告表明存在高风险时。</p>
        <p>
          本《用户隐私协议》是本站及相关主体系列产品用户协议的组成部分。因服务内容的特殊性，如您不接受我们基于本协议采集处理您的个人信息，或者您不明白或不接受本《用户隐私协议》的内容，我们客观上将无法为您提供后续服务。
        </p>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import {
  sendCodeApi,
  verifyPhoneApi,
  verificationCodeApi,
  submitFormApi,
  collectPointApi
} from "@/api/apiList.js"; // collectPointApi,
import { certifiedPhone } from "@/util/certifiedPhone.js";
import { mapGetters, mapMutations } from "vuex";
import { guid } from "@/util/util.js";
var pageTimer = {};
export default {
  name: "jgsform",
  props: {
    activityId: {
      type: String,
      default: "",
    },
    startTime: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "填写信息领取",
    },
    btnTitle: {
      type: String,
      default: "立即领取",
    },
    type: {
      type: String,
      default: "reservation",
    },
  },
  data() {
    return {
      rules: {},
      showCode: false,
      sendDisabled: false,
      sendText: "获取验证码",
      needCode: false,
      validatorPhoneFlag: false,
      initSendFlag: true,
      formData: {
        name: "",
        phone: "",
        code: "",
      },
      shoLoading: false,
      showAgreement: false,
      code: ""
    };
  },
  created() {
    this.code = this.$route.query.code || ''
    console.log(this.code,'ddjkd');
  },
  computed: {
    ...mapGetters(["pages", "UUID"]),
  },
  methods: {
    ...mapMutations(["setFillPhone", "setActivityId"]),
    validatorPhone(val) {
      // return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
      return /^1[23456789]\d{9}$/.test(val);
    },
    async changePhone(val) {
      if (this.validatorPhone(val)) {
        this.shoLoading = true;
        let res = await this.certifiedPhoneFun(val);
        this.shoLoading = false;
        if (res.flag) {
          this.validatorPhoneFlag = true;
          this.needCode = false;
        } else {
          this.validatorPhoneFlag = false;
          this.needCode = true;
          this.showCode = true;
          if (this.initSendFlag) {
            this.initSendFlag = false;
            this.sendCodeFun();
          }
        }
      } else {
        clearInterval(pageTimer["timer"]);
        this.sendDisabled = false;
        this.sendText = "获取验证码";
        this.showCode = false;
      }
    },
    onSubmit(values) {
      if (!this.validatorPhoneFlag) {
        console.log(values, "valuesvaluesvaluesvalues");
        verificationCodeApi({
          phoneNumber: values.phone,
          code: values.code,
        })
          .then((res) => {
            let data = res.data.data;
            console.log(data, "短信校验结果");
            if (data) {
              this.submitFormFun();
            }
          })
          .catch((err) => {
            console.log(err, "错误了");
          });
      } else {
        this.submitFormFun();
      }
      console.log("submit", values);
    },
    showAgreementFun() {
      this.showAgreement = true;
    },
    sendCodeFun() {
      if (this.validatorPhone(this.formData.phone)) {
        var time = 60;
        sendCodeApi({ phoneNumber: this.formData.phone })
          .then((res) => {
            let code = res.data.code;
            console.log(code, "hdkhk");
            console.log(res, "dhkd");
            if (code == "200") {
              this.$toast({
                message: "短信验证码已发送",
                duration: 3000,
              });
              this.sendDisabled = true;
              pageTimer["timer"] = setInterval(() => {
                if (time > 1) {
                  this.sendText = `${time}秒后重新获取`;
                  time--;
                } else {
                  clearInterval(pageTimer["timer"]);
                  this.sendDisabled = false;
                  this.sendText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$toast({
                message: res.data.message,
                duration: 3000,
              });
            }
          })
          .catch((err) => {
            this.sendDisabled = false;
            console.log(err, "获取短信验证码失败的报错");
          });
      } else {
        this.$toast("请输入正确的手机号");
      }
    },
    certifiedPhoneFun(phone) {
      return new Promise((resolve) => {
        certifiedPhone(this).then((res) => {
          console.log(res, "鉴权返回的结果");
          // 如果鉴权成功
          if (res && res.code == 600000) {
            // spToken
            let prarms = {
              phoneNumber: phone,
              spToken: res.spToken,
            };
            verifyPhoneApi(prarms)
              .then((verifyRes) => {
                console.log(verifyRes, "认证结果");
                let data = verifyRes.data?.data?.body || { code: "fail" };
                if (data.code === "OK") {
                  if (
                    data.gateVerify?.verifyResult === "PASS" ||
                    data.GateVerify?.VerifyResult === "PASS"
                  ) {
                    resolve({
                      flag: true,
                      data,
                    });
                  } else {
                    resolve({
                      flag: false,
                      data,
                    });
                  }
                } else {
                  resolve({
                    flag: false,
                    data,
                  });
                }
              })
              .catch((err) => {
                resolve({
                  flag: false,
                  data: err,
                });
              });
          } else {
            resolve({
              flag: false,
              data: {},
            });
          }
        });
      });
    },
    submitFormFun() {
      let unity = guid();
      let prarms = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        name: this.formData.name,
        phone: this.formData.phone,
        localUrl: location.href,
        unity,
      };
      let copyPrarms = JSON.parse(JSON.stringify(prarms));
      copyPrarms.uuid = this.UUID;
      if(this.code) {
        prarms.code = this.code
        console.log(prarms,'djdhk');
      }
      copyPrarms.eventType = 1;
      delete copyPrarms.name;
      if (this.startTime) {
        prarms.scanTime = +new Date() - this.startTime;
      }
      submitFormApi(prarms).then((res) => {
        let result = res.data.data;
        // 记录活动id和手机
        this.setFillPhone(prarms.phone);
        this.setActivityId(prarms.activityId);
        // 提交埋点
        collectPointApi(copyPrarms);
        // 成功
        if (result === 1) {
          this.$router.push(`/rec/result`);
        } else if (result === 2) {
          // 重复
          this.$router.push(`/tfhd/jiugongshan/success?repeat=1`);
        } else {
          // 失败
          this.$toast("预约失败, 请稍后重试...");
        }
        console.log(res, "提交表单结果");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-title {
  text-align: center;
  padding-bottom: 0.2rem;
  .title {
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.5rem;
    span {
      color: #b91b3f;
    }
  }
  .last-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.12rem;
    p {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6c340d;
      line-height: 0.4rem;
    }
    .ac-icon {
      width: 0.4rem;
      height: 0.3rem;
      margin-right: 0.04rem;
    }
    .co-icon {
      margin-left: 0.04rem;
      width: 0.5rem;
      height: 0.3rem;
    }
  }
}
.form-item /deep/ {
  padding: 0.22rem 0.2rem;
  border-radius: 0.08rem;
  border: 0.02rem solid #e5c6a9;
  font-size: 0.28rem;
  margin: 0.12rem 0 0.24rem;
  .van-field__label {
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.4rem;
    margin-right: 0.18rem;
    width: auto;
  }

  .sendBtn {
    background: #ff9d20;
    margin-right: -0.1rem;
    border-color: #ff9d20;
    .van-button__text {
      font-size: 0.26rem;
    }
  }
}
.submit-btn {
  width: 100%;
  height: 0.86rem;
  background: #ff9d20;
  border-radius: 0.43rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.agreement-item {
  padding-top: 0.12rem;
  p {
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.33rem;
    span {
      color: #0379fb;
    }
  }
}
.loading-g {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  // align-items: center;
  justify-content: center;
  z-index: 1000;
  padding-top: 5rem;
  .loading-wrap {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.agreementContent {
  padding-bottom: env(safe-area-inset-bottom);
  max-height: 70vh;
  overflow-y: auto;
  padding: 0.2rem;
  p {
    text-indent: 2em;
    font-size: 0.3rem;
    line-height: 0.5rem;
  }
}
</style>